<template>
  <div>
    <div class="d-flex mb-5 justify-space-between align-center">
      <p
        class="display-1 ma-0 text-uppercase"
        :style="
          $vuetify.breakpoint.mobile
            ? `text-align: center; font-size: 1.5rem!important; width: 100%`
            : ''
        "
      >
        {{ $t('title-quotation-list') }}
      </p>

      <div
        v-if="!$vuetify.breakpoint.mobile"
        class="d-flex align-center"
      >
        <v-badge
          color="red"
          offset-x="10"
          offset-y="10"
          v-if="filterCount > 0"
          class="mr-4"
        >
          <template v-slot:badge>
            {{ filterCount }}
          </template>
          <v-btn
            style="background: white"
            outlined
            color="error"
            width="160"
            @click="handleClearFilter"
          >
            {{ $t('button-clear-filter') }}
          </v-btn>
        </v-badge>

        <v-btn
          v-if="!isOpenFilters"
          class="primary--text"
          color="white"
          style="background: white"
          outlined
          width="160"
          @click.stop="handleToggleFilters"
        >
          <v-icon>mdi-filter-outline</v-icon>
          {{ $t('button-open-filter') }}
        </v-btn>

        <v-btn
          v-else
          class="red--text"
          style="background: white"
          outlined
          width="160"
          color="white"
          :disabled="isLoadingFilters"
          @click.stop="handleToggleFilters"
        >
          <v-icon>mdi-filter-off-outline</v-icon>
          {{ $t('button-close-filter') }}
        </v-btn>
      </div>
    </div>

    <v-navigation-drawer
      v-model="isOpenFilters"
      right
      app
      overflow
      width="300"
      v-if="$vuetify.breakpoint.mobile"
    >
      <v-card
        rounded="xl"
        v-if="isOpenFilters"
      >
        <MyQuotationFilters />
      </v-card>
    </v-navigation-drawer>

    <div class="pa-0">
      <div class="d-flex flex-column">
        <v-expand-transition>
          <v-card
            v-if="isOpenFilters && !$vuetify.breakpoint.mobile"
            rounded="xl"
          >
            <template transition="fade-transition">
              <MyQuotationFilters @set-loading="setIsLoadingFilters" />
            </template>
          </v-card>
        </v-expand-transition>
        <v-row
          class="my-4 align-center justify-space-between"
          no-gutters
        >
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 10 : 4"
            class="pr-6"
          >
            <v-card
              class="px-2"
              rounded="lg"
            >
              <v-card-text class="pa-0">
                <v-text-field
                  v-model="typedSearch"
                  :label="$t('label-quotation-search')"
                  prepend-inner-icon="mdi-magnify"
                  type="text"
                  solo
                  flat
                  hide-details
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="2"
            v-if="$vuetify.breakpoint.mobile"
            class="d-flex justify-end"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  color="red"
                  offset-x="10"
                  offset-y="10"
                  class="mr-2"
                >
                  <template v-slot:badge>
                    {{ filterCount }}
                  </template>
                  <v-btn
                    style="height: 48px"
                    class="blue--text rounded-lg"
                    color="white"
                    @click="handleToggleFilters"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-filter-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <span>{{ $t('button-filter') }}</span>
            </v-tooltip>
          </v-col>

          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 4"
            v-if="!$vuetify.breakpoint.mobile"
          >
            <v-pagination
              v-model="page"
              :value="page"
              @input="onChangePage"
              :length="myQuotationTotalPage"
            />
          </v-col>
        </v-row>
      </div>

      <v-pagination
        v-if="$vuetify.breakpoint.mobile"
        v-model="page"
        :value="page"
        @input="onChangePage"
        :length="myQuotationTotalPage"
      />

      <div style="min-height: 500px">
        <v-card
          color="background"
          elevation="0"
          rounded="xl"
        >
          <v-card-text
            style="font-size: 11px"
            :class="{ 'd-none': $vuetify.breakpoint.mobile }"
          >
            <v-row no-gutters>
              <v-col
                cols="8"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-voyage-info') }}
              </v-col>
              <v-col
                cols="2"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-status') }}
              </v-col>
              <v-col
                cols="2"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-created-at') }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <template v-if="!isLoading">
          <MyQuotationListItem
            v-for="item in myQuotationList"
            :key="item.id"
            :item="item"
            :to="{ path: '/app/shipping/my-quotation/' + item.id }"
          />
        </template>

        <template v-if="isLoading">
          <v-card
            v-for="(item, i) in 5"
            :key="i"
            class="mt-2 pa-3"
            rounded="xl"
          >
            <v-row
              no-gutters
              class="align-center"
            >
              <v-col
                cols="8"
                class="text-truncate"
              >
                <v-skeleton-loader
                  :boilerplate="false"
                  type="list-item-three-line, list-item-three-line"
                />
              </v-col>
              <v-col
                cols="2"
                class="d-flex justify-center align-center"
              >
                <v-skeleton-loader
                  :boilerplate="false"
                  type="button"
                />
              </v-col>
              <v-col
                cols="2"
                class="text-center text-truncate"
              >
                <v-skeleton-loader
                  :boilerplate="false"
                  type="list-item-two-line"
                />
              </v-col>
            </v-row>
          </v-card>
        </template>
        <NoData v-if="!isLoading && myQuotationList.length === 0" />
      </div>

      <v-pagination
        class="mt-3"
        v-model="page"
        v-if="!isLoading"
        :value="page"
        @input="onChangePage"
        :length="myQuotationTotalPage"
      />
    </div>
  </div>
</template>

<script>
import NoData from '@/components/shared/local/base/NoData.vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import MyQuotationListItem from './MyQuotationListItem.vue';
import queryString from 'query-string';
import MyQuotationFilters from './MyQuotationFilters.vue';
import { debounce, pluck } from 'rxjs/operators';
import { timer } from 'rxjs';
export default {
  components: { MyQuotationListItem, NoData, MyQuotationFilters },
  data: () => ({
    isLoading: false,
    isLoadingFilters: false,
    isOpenFilters: false,
    page: 0,
    typedSearch: '',
    filterCount: 0,
  }),
  computed: {
    ...mapState('vsl/quotation', [
      'myQuotationList',
      'myQuotationTotal',
      'myQuotationTotalPage',
      'myQuotationListQueryParams',
    ]),
  },
  mounted() {
    const parsedQS = this.$route.query;
    this.typedSearch = parsedQS.search || '';

    this.$watchAsObservable('typedSearch')
      .pipe(
        pluck('newValue'),
        debounce(() => {
          this.isLoading = true;
          return timer(500);
        })
      )
      .subscribe(async (value) => {
        this.SET_LIST_QUERY_PARAMS({
          search: value || undefined,
        });
      });

    this.SET_LIST_QUERY_PARAMS({
      search: parsedQS.search,
      page: parsedQS.page ? Number(parsedQS.page) : 0,
      size: parsedQS.size ? Number(parsedQS.size) : 10,
      voyageCode: parsedQS.voyage_code,
      vesselCode: parsedQS.vessel_code,
      status: parsedQS.status,
      polCode: parsedQS.pol_code,
      podCode: parsedQS.pod_code,
      polTermCode: parsedQS.pol_term_code,
      podTermCode: parsedQS.pod_term_code,
      fromCreatedAt: parsedQS.from_created_at,
      toCreatedAt: parsedQS.to_created_at,
      fromEtd: parsedQS.from_etd,
      toEtd: parsedQS.to_etd,
      fromEta: parsedQS.from_eta,
      toEta: parsedQS.to_eta,
      bookingUserId: parsedQS.booking_user_id,
    });
  },
  methods: {
    ...mapMutations('vsl/quotation', ['SET_LIST_QUERY_PARAMS']),
    ...mapActions('vsl/quotation', [
      'fetchMyQuotationList',
      'clearQueryParamsList',
    ]),

    async loadQuotationList() {
      try {
        this.isLoading = true;
        this.$router.push({
          query: {
            search: this.myQuotationListQueryParams.search,
            page: this.myQuotationListQueryParams.page,
            size: this.myQuotationListQueryParams.size,
            voyage_code: this.myQuotationListQueryParams.voyageCode,
            vessel_code: this.myQuotationListQueryParams.vesselCode,
            status: this.myQuotationListQueryParams.status,
            pol_code: this.myQuotationListQueryParams.polCode,
            pod_code: this.myQuotationListQueryParams.podCode,
            pol_term_code: this.myQuotationListQueryParams.polTermCode,
            pod_term_code: this.myQuotationListQueryParams.podTermCode,
            from_created_at: this.myQuotationListQueryParams.fromCreatedAt,
            to_created_at: this.myQuotationListQueryParams.toCreatedAt,
            from_etd: this.myQuotationListQueryParams.fromEtd,
            to_etd: this.myQuotationListQueryParams.toEtd,
            from_eta: this.myQuotationListQueryParams.fromEta,
            to_eta: this.myQuotationListQueryParams.toEta,
            booking_user_id: this.myQuotationListQueryParams.bookingUserId,
          },
        });

        await this.fetchMyQuotationList();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },

    onChangePage(value) {
      this.SET_LIST_QUERY_PARAMS({ page: value - 1 });
    },

    handleToggleFilters() {
      this.isOpenFilters = !this.isOpenFilters;
    },

    handleClearFilter() {
      this.clearQueryParamsList();
      this.filterCount = 0;
    },

    setIsLoadingFilters(bool) {
      this.isLoadingFilters = bool;
    },
  },

  watch: {
    myQuotationListQueryParams: {
      handler(value) {
        console.log('[LOG] : value', value);
        if (value.page !== null && value.size !== null) {
          this.loadQuotationList();
        }

        const tempQP = { ...value };
        delete tempQP.page;
        delete tempQP.size;
        delete tempQP.search;
        const count = Object.values(tempQP).filter((item) => !!item).length;
        this.filterCount = count;

        this.page = value.page + 1 || 1;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-pagination {
    justify-content: flex-end;
  }
  .v-skeleton-loader__list-item-three-line {
    height: 54px;
    width: 80%;
  }
  .v-skeleton-loader__button {
    width: 110px;
    height: 32px;
    border-radius: 16px;
  }
}
</style>
