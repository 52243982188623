var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-4 relative" },
    [
      _c(
        "div",
        { staticClass: "overline primary--text font-weight-regular" },
        [
          _vm._v(" Bộ lọc "),
          _c("v-icon", { attrs: { small: "", color: "primary" } }, [
            _vm._v("mdi-magnify"),
          ]),
        ],
        1
      ),
      _vm.isLoading
        ? _c("v-progress-circular", {
            staticStyle: {
              position: "absolute",
              top: "50%",
              left: "50%",
              "z-index": "6",
            },
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      _vm.isLoading
        ? _c("v-overlay", {
            attrs: { absolute: "", value: true, color: "white" },
          })
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              class: { "pa-2": _vm.$vuetify.breakpoint.mobile },
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3 },
            },
            [
              _c("div", { staticClass: "caption" }, [_vm._v("POL")]),
              _c("PortPicker", {
                staticClass: "pr-2",
                attrs: {
                  value: _vm.myQuotationListQueryParams.polCode,
                  label: _vm.$t("placeholder-pol"),
                  "no-self-load-data": "",
                  clearable: "",
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                },
                on: {
                  change: _vm.onChangePol,
                  "update-loading": _vm.setPortLoading,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: { "pa-2": _vm.$vuetify.breakpoint.mobile },
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3 },
            },
            [
              _c("div", { staticClass: "caption" }, [_vm._v("POD")]),
              _c("PortPicker", {
                attrs: {
                  label: _vm.$t("placeholder-pod"),
                  "no-self-load-data": "",
                  solo: "",
                  clearable: "",
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                  value: _vm.myQuotationListQueryParams.podCode,
                },
                on: { change: _vm.onChangePod },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: { "pa-2": _vm.$vuetify.breakpoint.mobile },
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3 },
            },
            [
              _c("div", { staticClass: "caption" }, [_vm._v("POL Term")]),
              _c("TermPicker", {
                attrs: {
                  label: _vm.$t("placeholder-pol-term"),
                  "no-self-load-data": "",
                  solo: "",
                  clearable: "",
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                  value: _vm.myQuotationListQueryParams.polTermCode,
                },
                on: {
                  change: _vm.onChangePolTerm,
                  "update-loading": _vm.setTermLoading,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: { "pa-2": _vm.$vuetify.breakpoint.mobile },
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3, sm: "3" },
            },
            [
              _c("div", { staticClass: "caption" }, [_vm._v("POD Term")]),
              _c("TermPicker", {
                attrs: {
                  solo: "",
                  "no-self-load-data": "",
                  clearable: "",
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                  label: _vm.$t("placeholder-pod-term"),
                  value: _vm.myQuotationListQueryParams.podTermCode,
                },
                on: { change: _vm.onChangePodTerm },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: { "pa-2": _vm.$vuetify.breakpoint.mobile },
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3, sm: "3" },
            },
            [
              _c("div", { staticClass: "caption" }, [_vm._v("ETA")]),
              _c(
                "v-dialog",
                {
                  ref: "dialog",
                  attrs: { persistent: "", width: "670px" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "BaseTextField",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    value: _vm.etaDatesText,
                                    placeholder: _vm.$t("placeholder-eta"),
                                    "prepend-inner-icon": "mdi-calendar-month",
                                    readonly: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    dense: "",
                                    "hide-details": "",
                                    clearable: "",
                                  },
                                  on: { "click:clear": _vm.onClearEta },
                                },
                                "BaseTextField",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.etaModal,
                    callback: function ($$v) {
                      _vm.etaModal = $$v
                    },
                    expression: "etaModal",
                  },
                },
                [
                  _c(
                    "v-date-picker",
                    {
                      attrs: {
                        range: "",
                        landscape: !_vm.$vuetify.breakpoint.mobile,
                        "full-width": "",
                      },
                      model: {
                        value: _vm.eta,
                        callback: function ($$v) {
                          _vm.eta = $$v
                        },
                        expression: "eta",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.etaModal = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button-cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.onSelectEta },
                        },
                        [_vm._v(" OK ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: { "pa-2": _vm.$vuetify.breakpoint.mobile },
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3, sm: "3" },
            },
            [
              _c("div", { staticClass: "caption" }, [
                _vm._v(_vm._s(_vm.$t("label-created-at"))),
              ]),
              _c(
                "v-dialog",
                {
                  ref: "dialog",
                  attrs: { persistent: "", width: "670px" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "BaseTextField",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    value: _vm.createdDateText,
                                    placeholder: _vm.$t(
                                      "placeholder-created-at"
                                    ),
                                    "prepend-inner-icon": "mdi-calendar-month",
                                    readonly: "",
                                    "persistent-placeholder": "",
                                    solo: "",
                                    "hide-details": "",
                                    clearable: "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: { "click:clear": _vm.onClearCreatedDate },
                                },
                                "BaseTextField",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.dialog.createdAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialog, "createdAt", $$v)
                    },
                    expression: "dialog.createdAt",
                  },
                },
                [
                  _c(
                    "v-date-picker",
                    {
                      attrs: {
                        range: "",
                        landscape: !_vm.$vuetify.breakpoint.mobile,
                        "full-width": "",
                      },
                      model: {
                        value: _vm.createdDate,
                        callback: function ($$v) {
                          _vm.createdDate = $$v
                        },
                        expression: "createdDate",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialog.createdAt = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.onSelectCreatedDate },
                        },
                        [_vm._v(" OK ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: { "pa-2": _vm.$vuetify.breakpoint.mobile },
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3, sm: "3" },
            },
            [
              _c("div", { staticClass: "caption" }, [
                _vm._v(_vm._s(_vm.$t("label-status"))),
              ]),
              _c("v-select", {
                attrs: {
                  "v-model": _vm.status,
                  value: _vm.status,
                  items: _vm.statusOptions,
                  label: _vm.$t("placeholder-status"),
                  "prepend-inner-icon": "mdi-pen-plus",
                  "item-text": "text",
                  "item-value": "value",
                  solo: "",
                  clearable: "",
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                },
                on: { change: _vm.onChangeStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }