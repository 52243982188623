var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mt-2",
      attrs: { rounded: "xl", ripple: false, to: _vm.to },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticClass: "align-center", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-truncate",
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 8 },
                },
                [
                  _c("ul", [
                    _c("li", [
                      _c("b", [_vm._v(_vm._s(_vm.$t("row-quotation-code")))]),
                      _c("span", [_vm._v(": " + _vm._s(_vm.item.code))]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v(_vm._s(_vm.$t("row-etd-eta")))]),
                      _c("span", [
                        _vm._v(
                          ": " +
                            _vm._s(_vm.$filters.formatDate(_vm.item.etd)) +
                            " - " +
                            _vm._s(_vm.$filters.formatDate(_vm.item.eta))
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v(_vm._s(_vm.$t("row-voyage")))]),
                      _c("span", [
                        _vm._v(": " + _vm._s(_vm.item.voyageCodes.join(", "))),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [
                        _vm._v(" " + _vm._s(_vm.$t("row-polpod")) + " "),
                      ]),
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v(
                          ": " +
                            _vm._s(_vm.item.polName) +
                            " (" +
                            _vm._s(_vm.item.polCode) +
                            ") - " +
                            _vm._s(_vm.item.podName) +
                            " (" +
                            _vm._s(_vm.item.podCode) +
                            ") "
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v(" Sản lượng laden ")]),
                      _c("span", [
                        _vm._v(
                          " : " +
                            _vm._s(
                              _vm.formatContainerCountByCodeSize(
                                _vm.item.ladenContainerCountByCodeSize
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v(" Sản lượng empty ")]),
                      _c("span", [
                        _vm._v(
                          " : " +
                            _vm._s(
                              _vm.formatContainerCountByCodeSize(
                                _vm.item.emptyContainerCountByCodeSize
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _vm.$vuetify.breakpoint.mobile
                      ? _c("li", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("col-status")) + " "),
                          ]),
                          _c("span", [
                            _vm._v(
                              " : " +
                                _vm._s(
                                  _vm.formatQuotationStatus(_vm.item.status)
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.$vuetify.breakpoint.mobile
                      ? _c("li", [
                          _c("b", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("col-created-at")) + " "
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              " : " +
                                _vm._s(
                                  _vm.$filters.formatTime(_vm.item.createdAt)
                                ) +
                                " " +
                                _vm._s(
                                  _vm.$filters.formatDate(_vm.item.createdAt)
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "justify-center",
                  class: !_vm.$vuetify.breakpoint.mobile
                    ? `d-flex text-truncate`
                    : `d-none`,
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.formatQuotationStatusColor(_vm.item.status),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatQuotationStatus(_vm.item.status)) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center text-truncate",
                  class: { "d-none": _vm.$vuetify.breakpoint.mobile },
                  attrs: { cols: "2" },
                },
                [
                  _c("div", [
                    _vm._v(_vm._s(_vm.$filters.formatTime(_vm.item.createdAt))),
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$filters.formatDate(_vm.item.createdAt))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }