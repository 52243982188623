<template>
  <v-card
    class="mt-2"
    rounded="xl"
    :ripple="false"
    :to="to"
  >
    <v-card-text>
      <v-row
        no-gutters
        class="align-center"
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 8"
          class="text-truncate"
        >
          <ul>
            <li>
              <b>{{ $t('row-quotation-code') }}</b>
              <span>: {{ item.code }}</span>
            </li>
            <li>
              <b>{{ $t('row-etd-eta') }}</b
              ><span
                >: {{ $filters.formatDate(item.etd) }} -
                {{ $filters.formatDate(item.eta) }}</span
              >
            </li>
            <li>
              <b>{{ $t('row-voyage') }}</b
              ><span>: {{ item.voyageCodes.join(', ') }}</span>
            </li>

            <li>
              <b>
                {{ $t('row-polpod') }}
              </b>
              <span class="text-uppercase"
                >: {{ item.polName }} ({{ item.polCode }}) -
                {{ item.podName }} ({{ item.podCode }})
              </span>
            </li>
            <li>
              <b> Sản lượng laden </b>
              <span>
                :
                {{
                  formatContainerCountByCodeSize(
                    item.ladenContainerCountByCodeSize
                  )
                }}
              </span>
            </li>
            <li>
              <b> Sản lượng empty </b>
              <span>
                :
                {{
                  formatContainerCountByCodeSize(
                    item.emptyContainerCountByCodeSize
                  )
                }}
              </span>
            </li>
            <li v-if="$vuetify.breakpoint.mobile">
              <b>
                {{ $t('col-status') }}
              </b>
              <span>
                :
                {{ formatQuotationStatus(item.status) }}
              </span>
            </li>

            <li v-if="$vuetify.breakpoint.mobile">
              <b>
                {{ $t('col-created-at') }}
              </b>
              <span>
                :
                {{ $filters.formatTime(item.createdAt) }}
                {{ $filters.formatDate(item.createdAt) }}
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="2"
          class="justify-center"
          :class="
            !$vuetify.breakpoint.mobile ? `d-flex text-truncate` : `d-none`
          "
        >
          <v-chip :color="formatQuotationStatusColor(item.status)">
            {{ formatQuotationStatus(item.status) }}
          </v-chip>
        </v-col>
        <v-col
          cols="2"
          class="text-center text-truncate"
          :class="{ 'd-none': $vuetify.breakpoint.mobile }"
        >
          <div>{{ $filters.formatTime(item.createdAt) }}</div>
          <div>{{ $filters.formatDate(item.createdAt) }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  formatQuotationStatus,
  formatQuotationStatusColor,
} from '@/services/quotation.service';
import i18n from '@/i18n';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: Object,
    },
  },
  components: {},
  data: () => ({}),
  computed: {},
  async created() {},
  methods: {
    formatQuotationStatus,
    formatQuotationStatusColor,
    formatContainerCountByCodeSize(item) {
      if (!item || item?.length === 0) {
        return i18n.t('text-no-value');
      }
      return item
        .map(({ code, quantity }) => [`${quantity}x${code}`])
        .join(', ');
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  display: table;
  width: 100%;
  table-layout: fixed;
  padding-left: 8px;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  width: 155px;
}

span {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
