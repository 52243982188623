var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex mb-5 justify-space-between align-center" },
        [
          _c(
            "p",
            {
              staticClass: "display-1 ma-0 text-uppercase",
              style: _vm.$vuetify.breakpoint.mobile
                ? `text-align: center; font-size: 1.5rem!important; width: 100%`
                : "",
            },
            [_vm._v(" " + _vm._s(_vm.$t("title-quotation-list")) + " ")]
          ),
          !_vm.$vuetify.breakpoint.mobile
            ? _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _vm.filterCount > 0
                    ? _c(
                        "v-badge",
                        {
                          staticClass: "mr-4",
                          attrs: {
                            color: "red",
                            "offset-x": "10",
                            "offset-y": "10",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "badge",
                                fn: function () {
                                  return [
                                    _vm._v(" " + _vm._s(_vm.filterCount) + " "),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3534482390
                          ),
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { background: "white" },
                              attrs: {
                                outlined: "",
                                color: "error",
                                width: "160",
                              },
                              on: { click: _vm.handleClearFilter },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("button-clear-filter")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isOpenFilters
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          staticStyle: { background: "white" },
                          attrs: { color: "white", outlined: "", width: "160" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleToggleFilters.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-filter-outline")]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("button-open-filter")) + " "
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "red--text",
                          staticStyle: { background: "white" },
                          attrs: {
                            outlined: "",
                            width: "160",
                            color: "white",
                            disabled: _vm.isLoadingFilters,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleToggleFilters.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-filter-off-outline")]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("button-close-filter")) + " "
                          ),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { right: "", app: "", overflow: "", width: "300" },
              model: {
                value: _vm.isOpenFilters,
                callback: function ($$v) {
                  _vm.isOpenFilters = $$v
                },
                expression: "isOpenFilters",
              },
            },
            [
              _vm.isOpenFilters
                ? _c(
                    "v-card",
                    { attrs: { rounded: "xl" } },
                    [_c("MyQuotationFilters")],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pa-0" },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "v-expand-transition",
                [
                  _vm.isOpenFilters && !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-card",
                        { attrs: { rounded: "xl" } },
                        [
                          [
                            _c("MyQuotationFilters", {
                              on: { "set-loading": _vm.setIsLoadingFilters },
                            }),
                          ],
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "my-4 align-center justify-space-between",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pr-6",
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 10 : 4 },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "px-2", attrs: { rounded: "lg" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("label-quotation-search"),
                                  "prepend-inner-icon": "mdi-magnify",
                                  type: "text",
                                  solo: "",
                                  flat: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.typedSearch,
                                  callback: function ($$v) {
                                    _vm.typedSearch = $$v
                                  },
                                  expression: "typedSearch",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-end",
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-badge",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              color: "red",
                                              "offset-x": "10",
                                              "offset-y": "10",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "badge",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.filterCount
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "blue--text rounded-lg",
                                                    staticStyle: {
                                                      height: "48px",
                                                    },
                                                    attrs: { color: "white" },
                                                    on: {
                                                      click:
                                                        _vm.handleToggleFilters,
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-filter-outline"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1531178308
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("button-filter"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-col",
                        {
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4,
                          },
                        },
                        [
                          _c("v-pagination", {
                            attrs: {
                              value: _vm.page,
                              length: _vm.myQuotationTotalPage,
                            },
                            on: { input: _vm.onChangePage },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mobile
            ? _c("v-pagination", {
                attrs: { value: _vm.page, length: _vm.myQuotationTotalPage },
                on: { input: _vm.onChangePage },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "min-height": "500px" } },
            [
              _c(
                "v-card",
                {
                  attrs: { color: "background", elevation: "0", rounded: "xl" },
                },
                [
                  _c(
                    "v-card-text",
                    {
                      class: { "d-none": _vm.$vuetify.breakpoint.mobile },
                      staticStyle: { "font-size": "11px" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold text-uppercase",
                              attrs: { cols: "8" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("col-voyage-info")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center font-weight-bold text-uppercase",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("col-status")) + " ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center font-weight-bold text-uppercase",
                              attrs: { cols: "2" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("col-created-at")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isLoading
                ? _vm._l(_vm.myQuotationList, function (item) {
                    return _c("MyQuotationListItem", {
                      key: item.id,
                      attrs: {
                        item: item,
                        to: { path: "/app/shipping/my-quotation/" + item.id },
                      },
                    })
                  })
                : _vm._e(),
              _vm.isLoading
                ? _vm._l(5, function (item, i) {
                    return _c(
                      "v-card",
                      {
                        key: i,
                        staticClass: "mt-2 pa-3",
                        attrs: { rounded: "xl" },
                      },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "align-center",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                attrs: { cols: "8" },
                              },
                              [
                                _c("v-skeleton-loader", {
                                  attrs: {
                                    boilerplate: false,
                                    type: "list-item-three-line, list-item-three-line",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex justify-center align-center",
                                attrs: { cols: "2" },
                              },
                              [
                                _c("v-skeleton-loader", {
                                  attrs: { boilerplate: false, type: "button" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center text-truncate",
                                attrs: { cols: "2" },
                              },
                              [
                                _c("v-skeleton-loader", {
                                  attrs: {
                                    boilerplate: false,
                                    type: "list-item-two-line",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
              !_vm.isLoading && _vm.myQuotationList.length === 0
                ? _c("NoData")
                : _vm._e(),
            ],
            2
          ),
          !_vm.isLoading
            ? _c("v-pagination", {
                staticClass: "mt-3",
                attrs: { value: _vm.page, length: _vm.myQuotationTotalPage },
                on: { input: _vm.onChangePage },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }